// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-apoiar-jsx": () => import("./../../../src/pages/apoiar.jsx" /* webpackChunkName: "component---src-pages-apoiar-jsx" */),
  "component---src-pages-contactos-jsx": () => import("./../../../src/pages/contactos.jsx" /* webpackChunkName: "component---src-pages-contactos-jsx" */),
  "component---src-pages-imprensa-jsx": () => import("./../../../src/pages/imprensa.jsx" /* webpackChunkName: "component---src-pages-imprensa-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-inspiracao-jsx": () => import("./../../../src/pages/inspiracao.jsx" /* webpackChunkName: "component---src-pages-inspiracao-jsx" */),
  "component---src-pages-novidades-jsx": () => import("./../../../src/pages/novidades.jsx" /* webpackChunkName: "component---src-pages-novidades-jsx" */),
  "component---src-pages-privacidade-jsx": () => import("./../../../src/pages/privacidade.jsx" /* webpackChunkName: "component---src-pages-privacidade-jsx" */),
  "component---src-pages-projetos-jsx": () => import("./../../../src/pages/projetos.jsx" /* webpackChunkName: "component---src-pages-projetos-jsx" */),
  "component---src-pages-termos-e-condicoes-jsx": () => import("./../../../src/pages/termos-e-condicoes.jsx" /* webpackChunkName: "component---src-pages-termos-e-condicoes-jsx" */),
  "component---src-pages-voluntariar-jsx": () => import("./../../../src/pages/voluntariar.jsx" /* webpackChunkName: "component---src-pages-voluntariar-jsx" */),
  "component---src-templates-novidades-layout-jsx": () => import("./../../../src/templates/novidades-layout.jsx" /* webpackChunkName: "component---src-templates-novidades-layout-jsx" */),
  "component---src-templates-projetos-layout-jsx": () => import("./../../../src/templates/projetos-layout.jsx" /* webpackChunkName: "component---src-templates-projetos-layout-jsx" */)
}

